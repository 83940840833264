<template>
  <CRow>
    <CCol col="12" lg="10">
      <CCard>
        <CCardHeader>
          Create Remediation Item
        </CCardHeader>
        <CCardBody>
          <p class="text-center" v-show="loading">
            <CSpinner />
          </p >   
          <span v-show="!loading">
            <CCardBody class="p-4">
              <CForm>
                <CAlert
                  :show.sync="itemCreated"
                  closeButton
                  color="success"
                >
                Remediation Item {{ itemTitle }} created          
                </CAlert>                 
                <ASelect
                  label="Lense"
                  :options="lenses"
                  :value.sync='selectedLense'
                  @change="reloadChoices($event)"
                  :disabled="inputDisabled"
                  >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </ASelect>    

                <ASelect
                  label="Pillar"
                  :options="pillars"
                  :value.sync='selectedPillar'
                  @change="reloadChoices($event)"
                  :disabled="inputDisabled"
                  >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </ASelect>    

                <CInput
                  label="Title"
                  placeholder="Title"
                  v-model="itemTitle"
                  valid-feedback="Title is valid."
                  invalid-feedback="Minimum length is 4 and only characters and spaces."
                  :is-valid="validator"
                  :disabled="inputDisabled"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>

                <CSelect
                  label="Effort"
                  v-model="selectedEffort"
                  :value.sync="selectedEffort"
                  :options="effortList"
                  :disabled="inputDisabled"
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CSelect>     
                <div>
                  <p>Description</p>
                  <p>
                    <quill-editor
                      label="Description"
                      ref="myQuillEditor"
                      v-model="itemDesc"                
                      :options="editorOption"
                      :disabled="inputDisabled"
                    />
                  </p>
                </div>                   
              <template>
                <div>
                  <p>Choices</p>
                  <p>   
                  <multiselect
                    :value="selectedChoiceList"
                    :options="choices"
                    track-by="id"
                    :searchable="true"
                    :close-on-select="false"
                    :multiple="true"
                    label="name" 
                    :hideSelected="true"
                    @select="selectChoice"
                    @remove="removeChoice"
                    :loading="choicesLoading"
                    :disabled="inputDisabled"
                    placeholder="Pick choices"
                  >
                   </multiselect>
                  </p>
                </div>
              </template>        

              </CForm>
            </CCardBody>          
          </span>
        </CCardBody>
        <CCardFooter>
          <CButton color="light" class="col-sm-2" @click="goBack">Back</CButton>
          <CButton v-on:click="newItem();" color="success" class="float-right col-sm-4" v-show="!nextDisabled" :disabled="nextDisabled" >Next Item</CButton>
          <CButton v-on:click="saveItem();" color="success"  class="float-right col-sm-4" v-show="!inputDisabled">Create Item</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>



<script>
import ASelect from '@/components/forms/ASelect.vue'
import Multiselect from 'vue-multiselect'
import LenseService from '@/services/lense.services';
import ItemService from '@/services/item.services';
import ChoiceService from '@/services/choice.services';

export default {
  components: {
    ASelect,
    Multiselect
  },  
  data() {
    return {
      item: {},
      lenses: {},
      pillars: {},      
      choices: [],            
      itemTitle: null,
      itemDesc: "",
      selectedLense: null,
      selectedPillar: null,
      selectedChoiceList: [],
      selectedEffort: 0.25,
      effortList: [ 0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 2.25, 2.5, 2.75, 3.0, 3.25, 3.5, 3.75, 4.0  ],
      editorOption: { },
      itemCreated: false,
      inputDisabled: false,
      nextDisabled: true,
      loading: true,
      choicesLoading: true
    };
  },

  methods: {
    validator (val) {
        var valid = false;
        
        const regex = new RegExp('^([a-zA-Z0-9-_\\.&!#=()\\s]){4,}$');
        valid = val ? regex.test(val)  : false;
        
        this.createDisabled = !valid;

        return valid
    }, 
    newItem() {
        this.itemTitle = null;
        this.selectedChoiceList = [];
        this.itemDesc = "";
        this.selectedEffort = 0.25;
        this.itemCreated = false;
        this.inputDisabled = false;
        this.nextDisabled = true;
    },
    goBack() {
      this.$router.push({path: '/remediation-items'})
    },
    reloadChoices() {
      this.getChoicesByLensePillar()
    },
    selectChoice(selectedChoice) {
      var tmp = {}
      tmp['id'] = selectedChoice.id
      tmp['name'] = selectedChoice.name
      this.selectedChoiceList.push(tmp)
    },
    removeChoice(removedOption, id) {
      const index = this.selectedChoiceList.indexOf(removedOption);
      if (index > -1) {
         this.selectedChoiceList.splice(index, 1);
      }
    },
    // onEditorBlur(quill) {
    //   // console.log('editor blur!', quill)
    // },
    // onEditorFocus(quill) {
    //   // console.log('editor focus!', quill)
    // },
    // onEditorReady(quill) {
    //   // console.log('editor ready!', quill)
    // },
    // onEditorChange({ quill, html, text }) {
    //   // console.log('editor change!', quill, html, text)
    //   // this.itemDesc = html
    // },
    getChoicesByLensePillar() {
      ChoiceService.getChoicesByLensePillar(this.selectedLense, this.selectedPillar).then(
        response => { 
          // clear choice list
          this.choicesLoading = true;
          this.choices = []
          
          for (var key in response){
            var tmp = {}
            tmp["id"] = response[key].choice_id
            tmp["name"] = response[key].choice_name
            tmp["question_id"] = response[key].choice_question_id
            tmp["question_title"] = response[key].choice_question
        
            this.choices.push(tmp)
          }
          this.choicesLoading = false;
        },
        error => {   
          console.log(error)   
        }           
      );
    },
    async saveItem() {

      var tmp_choices = []
      for (var key in this.selectedChoiceList) {
        tmp_choices.push(this.selectedChoiceList[key].id)
      }

      ItemService.createItem(this.itemDesc, this.itemTitle, this.selectedLense, tmp_choices, this.selectedPillar, this.selectedEffort).then(
        response => { 
          this.itemCreated = true
          this.inputDisabled = true
          this.nextDisabled = false          
        },
        error => {   
          console.log(error)   
        }
      )
    },   
  },
  created() { },
  async mounted() { 

    await LenseService.getLenses().then(
      response => { 
        this.lenses = response;
        this.selectedLense = Object.keys(this.lenses)[0];
      },
      error => {   
        console.log(error)   
      }      
    );
    await LenseService.getPillars().then(
      response => { 
        this.pillars = response;
        this.selectedPillar = Object.keys(this.pillars)[0];
      },
      error => {   
        console.log(error)   
      }      
    );
    await ChoiceService.getChoices().then(
      response => { 
        this.choices = response;
      },
      error => {   
        console.log(error)   
      }
    );
    this.getChoicesByLensePillar();
    this.loading = false;
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
